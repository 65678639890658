{
  "name": "nodecg",
  "description": "Dynamic broadcast graphics rendered in a browser",
  "main": "index.js",
  "version": "2.2.1",
  "repository": {
    "type": "git",
    "url": "https://github.com/nodecg/nodecg.git"
  },
  "bugs": "https://github.com/nodecg/nodecg/issues",
  "homepage": "https://nodecg.dev/",
  "license": "MIT",
  "keywords": [
    "graphics",
    "nodecg",
    "node",
    "dynamic",
    "broadcast"
  ],
  "dependencies": {
    "@nodecg/json-schema-defaults": "^1.0.4",
    "@polymer/app-layout": "^3.1.0",
    "@polymer/app-route": "^3.0.2",
    "@polymer/iron-collapse": "^3.0.1",
    "@polymer/iron-flex-layout": "^3.0.1",
    "@polymer/iron-icons": "^3.0.1",
    "@polymer/iron-image": "^3.0.2",
    "@polymer/iron-localstorage": "^3.0.1",
    "@polymer/iron-pages": "^3.0.1",
    "@polymer/iron-selector": "^3.0.1",
    "@polymer/paper-button": "^3.0.1",
    "@polymer/paper-card": "^3.0.1",
    "@polymer/paper-dialog": "^3.0.1",
    "@polymer/paper-dialog-behavior": "^3.0.1",
    "@polymer/paper-dialog-scrollable": "^3.0.1",
    "@polymer/paper-icon-button": "^3.0.2",
    "@polymer/paper-item": "^3.0.1",
    "@polymer/paper-slider": "^3.0.1",
    "@polymer/paper-spinner": "^3.0.2",
    "@polymer/paper-styles": "^3.0.1",
    "@polymer/paper-tabs": "^3.1.0",
    "@polymer/paper-toast": "^3.0.1",
    "@polymer/paper-toolbar": "^3.0.1",
    "@polymer/polymer": "^3.5.1",
    "@sentry/browser": "^7.91.0",
    "@sentry/node": "^7.91.0",
    "@vaadin/vaadin-upload": "^4.4.3",
    "@webcomponents/webcomponentsjs": "^2.8.0",
    "ajv": "^8.12.0",
    "ajv-draft-04": "^1.0.0",
    "ajv-formats": "^2.1.1",
    "app-root-path": "^3.1.0",
    "better-sqlite3": "^8.7.0",
    "body-parser": "^1.20.2",
    "cheerio": "^1.0.0-rc.2",
    "chokidar": "^3.5.3",
    "clipboard": "^2.0.11",
    "compression": "^1.7.4",
    "connect-typeorm": "^2.0.0",
    "cookie-parser": "^1.4.6",
    "cookies-js": "^1.2.3",
    "cosmiconfig": "^8.3.6",
    "draggabilly": "^2.4.1",
    "events": "^3.3.0",
    "express": "^4.18.2",
    "express-session": "^1.17.3",
    "express-transform-bare-module-specifiers": "^1.0.4",
    "extend": "^3.0.2",
    "fast-equals": "^5.0.1",
    "fast-memoize": "^2.5.2",
    "fp-ts": "^2.16.1",
    "fs-extra": "^10.1.0",
    "git-rev-sync": "^3.0.2",
    "hasha": "^5.2.2",
    "httpolyglot": "^0.1.2",
    "iframe-resizer": "^4.3.9",
    "io-ts": "^2.2.21",
    "is-error": "^2.2.2",
    "json-ptr": "^3.1.1",
    "json-schema-lib": "github:nodecg/json-schema-lib#dcb89d670c2b15cd398916209bb806b2e100f95f",
    "klona": "^2.0.6",
    "lodash.debounce": "^4.0.8",
    "lodash.template": "^4.5.0",
    "multer": "^1.4.5-lts.1",
    "node-fetch-commonjs": "^3.3.2",
    "object-path": "^0.11.8",
    "packery": "^2.1.2",
    "passport": "^0.6.0",
    "passport-discord": "^0.1.4",
    "passport-local": "^1.0.0",
    "passport-steam": "^1.0.18",
    "passport-twitch-helix": "^1.1.0",
    "process": "^0.11.10",
    "semver": "^7.5.4",
    "serialize-error": "^8.1.0",
    "socket.io": "^4.7.2",
    "socket.io-client": "^4.7.2",
    "soundjs": "^1.0.1",
    "tslib": "^2.6.2",
    "typeorm": "0.3.19",
    "util": "^0.12.5",
    "uuid": "^9.0.1",
    "winston": "^3.11.0",
    "yargs": "^15.4.1",
    "zod": "^3.22.4"
  },
  "scripts": {
    "start": "node index.js",
    "dev": "del-cli dist out && run-p dev:*",
    "dev:client": "npm run build:client -- --watch",
    "dev:build-server": "tsc -b src/server --watch --preserveWatchOutput",
    "dev:run-server": "node --watch --watch-preserve-output index.js",
    "test": "ava --config ava.config.js",
    "test-coverage": "c8 ava --config ava.config.js",
    "test-coverage-ci": "c8 --reporter lcov ava --config ava.config.js",
    "test:types": "cd typetest/fake-bundle && npm run build",
    "lint": "npm-run-all -s lint:*",
    "lint:prettier": "prettier --check \"**/*.{json,md,yml,ts,tsx,js,jsx}\"",
    "lint:eslint": "eslint --cache \"{src,test}/**/*.ts\"",
    "fix": "npm-run-all -s fix:*",
    "fix:prettier": "prettier --write \"**/*.{json,md,yml,ts,tsx,js,jsx}\"",
    "fix:eslint": "npm run lint:eslint -- --fix ",
    "build": "del-cli \"generated-types/{client,server,shared,types}\" dist out && run-s build:{tsc,client,copy-templates,types}",
    "build:tsc": "tsc -b src/server src/client",
    "build:client": "ts-node scripts/build-client.ts",
    "build:copy-templates": "cpy \"src/server/**/*.tmpl\" out/server",
    "build:types": "ts-node scripts/generate-types-package.ts",
    "generate-migrations": "cd src/server && typeorm-ts-node-commonjs migration:generate ./database/migration/rename-me -d ./database/datasource.ts"
  },
  "files": [
    "dist",
    "out",
    "scripts",
    "bundles/.empty_directory",
    "db/.empty_directory",
    "schemas",
    "AUTHORS"
  ],
  "devDependencies": {
    "@types/app-root-path": "^1.2.8",
    "@types/cheerio": "^0.22.35",
    "@types/clone": "^2.1.4",
    "@types/compression": "^1.7.5",
    "@types/cookie-parser": "^1.4.6",
    "@types/deep-equal": "^1.0.4",
    "@types/express": "^4.17.21",
    "@types/express-session": "^1.17.10",
    "@types/extend": "^3.0.4",
    "@types/fs-extra": "^9.0.13",
    "@types/git-rev-sync": "^2.0.2",
    "@types/glob": "^8.1.0",
    "@types/is-ci": "^3.0.4",
    "@types/lodash.debounce": "^4.0.9",
    "@types/lodash.template": "^4.5.3",
    "@types/multer": "^1.4.11",
    "@types/node": "~18.11.19",
    "@types/object-path": "^0.11.4",
    "@types/passport": "^1.0.16",
    "@types/passport-local": "^1.0.38",
    "@types/passport-steam": "^1.0.5",
    "@types/semver": "^7.5.6",
    "@types/sinon": "^10.0.20",
    "@types/soundjs": "^0.6.31",
    "@types/uuid": "^8.3.4",
    "@types/yargs": "^15.0.19",
    "@typescript-eslint/eslint-plugin": "^6.17.0",
    "@typescript-eslint/parser": "^6.17.0",
    "ava": "^5.3.1",
    "c8": "^8.0.1",
    "cpy-cli": "^5.0.0",
    "cross-env": "^7.0.3",
    "del-cli": "^5.1.0",
    "esbuild": "^0.19.11",
    "esbuild-plugin-copy": "^2.1.1",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-ava": "^14.0.0",
    "is-builtin-module": "^3.2.1",
    "is-ci": "^3.0.1",
    "is-windows": "^1.0.2",
    "npm-run-all": "^4.1.5",
    "prettier": "^3.1.1",
    "puppeteer": "^21.6.1",
    "replace-in-file": "^6.3.5",
    "simple-git": "^3.22.0",
    "sinon": "^14.0.2",
    "tmp-promise": "^3.0.3",
    "ts-essentials": "^9.4.1",
    "ts-node": "^10.9.2",
    "typescript": "~5.3.3"
  },
  "engines": {
    "node": "^16.13.0 || ^18.12.0 || ^20.9.0"
  }
}
